<template>
  <nav class="navbar is-primary">
    <div class="container">
      <div class="navbar-brand">
        <div
          :class="{ 'is-active': openBurger }"
          class="navbar-burger "
          data-target="navMenu"
          @click="toggleMenu"
        >
          <span />
          <span />
          <span />
        </div>
      </div>
      <div
        id="navMenu"
        :class="{ 'is-active': openBurger }"
        class="navbar-menu"
      >
        <div class="navbar-end">
          <router-link
            to="/"
            class="navbar-item"
          >
            <b-icon icon="magnify"></b-icon>
            <span class="margin-left">Consulta de Infração</span>
          </router-link>
          <router-link
            class="navbar-item"
            to="/edital"
          >
            <b-icon icon="folder" />
            <span class="margin-left">Editais</span>
          </router-link>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              <b-icon icon="file-document" />
              <span class="margin-left">Formulários</span>
            </a>
            <div class="navbar-dropdown">
              <a
                :href="indicacao"
                class="navbar-item"
                target="_blank"
              >
                Indicação de Condutor
              </a>
              <a
                :href="advertencia"
                class="navbar-item"
                target="_blank"
              >
                Advertência
              </a>
              <a
                :href="defesa"
                class="navbar-item"
                target="_blank"
              >
                Defesa de Autuação
              </a>
              <a
                :href="jari"
                class="navbar-item"
                target="_blank"
              >
                Jari
              </a>
              <a
                :href="cetran"
                class="navbar-item"
                target="_blank"
              >
                Cetran
              </a>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable navEquip">
            <a class="navbar-link is-arrowless" :href="equipamentos">
              <b-icon icon="camera" />
              <span class="margin-left">Fiscalização Eletrônica</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      openBurger: false
    }
  },
  computed: {
    indicacao () {
      return `${window.location.origin}/${process.env.VUE_APP_CLIENTID}/indicacao.pdf`
    },
    advertencia () {
      return `${window.location.origin}/${process.env.VUE_APP_CLIENTID}/advertencia.pdf`
    },
    defesa () {
      return `${window.location.origin}/${process.env.VUE_APP_CLIENTID}/defesa.pdf`
    },
    jari () {
      return `${window.location.origin}/${process.env.VUE_APP_CLIENTID}/jari.pdf`
    },
    cetran () {
      return `${window.location.origin}/${process.env.VUE_APP_CLIENTID}/cetran.pdf`
    },
    equipamentos () {
      return `${window.location.origin}/${process.env.VUE_APP_CLIENTID}/equipamentos.pdf`
    }
  },
  methods: {
    toggleMenu () {
      this.openBurger = !this.openBurger
    }
  }
}
</script>
